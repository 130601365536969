import { StrictMode, useEffect } from "react";
import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
  useLocation,
} from "react-router-dom";
import { createRoot } from "react-dom/client";
import Cookies from "universal-cookie";

// Components
import HomePage from "@/pages/Home";
import NotFound from "@/pages/NotFound";
import { I18nextProvider } from "react-i18next";

// internationalization
import global_en from "@/translation/En/global.json";
import global_es from "@/translation/Es/global.json";
import i18next from "i18next";

import { Footer } from "@/components/Footer";
import { Header } from "@/components/Header";
import { SupportButton } from "@/components/SupportButton";
import "./index.css";
import Faqs from "./pages/Faqs";

// pages
import {
  AccelNetwork,
  AtmNetwork,
  CoopNetwork,
  MoneyPassNetwork,
} from "@/pages/AtmNetwork";
import Legal from "@/pages/Legal";
import BannerBank from "@/pages/BannerBank";
import WhySendolaPage from "@/pages/why-sendola";
import HowItWorksPage from "@/pages/how-it-works";
import ForPartnersPage from "@/pages/for-partners";
import { SendolaBlackPage } from "@/pages/sendola-black";

const cookies = new Cookies();

const DEFAULT_LANGUAGE = "en";

i18next.init({
  interpolation: { escapeValue: false },
  lng: cookies.get("lang") || DEFAULT_LANGUAGE,
  resources: {
    es: {
      global: global_es,
    },
    en: {
      global: global_en,
    },
  },
});

const Layout = () => {
  const location = useLocation();
  const pathname = location?.pathname;

  useEffect(() => {
    scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, [pathname]);

  return (
    <div className="flex flex-col min-h-screen">
      <Header />

      <div className="flex-1 flex">
        <Outlet />
      </div>
      <SupportButton />
      <Footer />
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        index: true,
        path: "",
        element: <HomePage />,
      },
      {
        path: "/atm-network",
        element: <AtmNetwork />,
      },
      {
        path: "/coop-network",
        element: <CoopNetwork />,
      },
      {
        path: "/moneypass-network",
        element: <MoneyPassNetwork />,
      },
      {
        path: "/accel-network",
        element: <AccelNetwork />,
      },
      {
        path: "/legal",
        element: <Legal />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
      {
        path: "/faqs",
        element: <Faqs />,
      },
      {
        path: "/banner-bank",
        element: <BannerBank />,
      },
      {
        path: "/why-sendola",
        element: <WhySendolaPage />,
      },
      {
        path: "/how-it-works",
        element: <HowItWorksPage />,
      },
      {
        path: "/for-partners",
        element: <ForPartnersPage />,
      },
      {
        path: "/sendola-black",
        element: <SendolaBlackPage />,
      },
    ],
  },
]);

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <I18nextProvider i18n={i18next}>
      <RouterProvider router={router} />
    </I18nextProvider>
  </StrictMode>
);
