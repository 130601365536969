import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { Button } from "@/components/Button";
import { Card } from "@/components/Card";
import { Input } from "@/components/Input";
import { Selector } from "@/components/Selector";
import { Select } from "@/components/Select";
import { useCatalogStore } from "@/stores/catalogs/country.store";
import { useQuoterStore } from "@/stores/quoter/quoter.store";
import LoadingIcon from "@/assets/Icons/loading.svg?react";
import { SendingMethods } from "@/interfaces/quoter.interface";
import { Destination, ICountry } from "@/interfaces/catalogs.interface";
import { useDebounce } from "@/hooks/useDebounce";
import USIcon from "@/assets/Img/Flags/USIcon.png";

const MAX_AMOUNT = 10000;
const DEFAULT_AMOUNT = 500;
const AUTH_DOMAIN = import.meta.env.VITE_AUTH_DOMAIN;

const ALLOWED_COUNTRIES = [
  "MEX",
  "GTM",
  "SLV",
  "HND",
  "IND",
  "PHL",
  "NIC",
  "CAN",
  "PAN",
  "VNM",
];

export const GlobalPaymentsQuoter = () => {
  const [t, i18n] = useTranslation("global");
  const currentLanguage = i18n.language;
  const [loadingQuote, setLoadingQuote] = useState<boolean>(false);
  const [loadingCountries, setLoadingCountries] = useState<boolean>(false);
  const [loadingDestinations, setLoadingDestinations] =
    useState<boolean>(false);
  const [amount, setAmount] = useState<number>(DEFAULT_AMOUNT);
  const [quoteAmount, setQuoteAmount] = useState<number>(DEFAULT_AMOUNT);
  const [selectedDestination, setSelectedDestination] = useState<Destination>();
  const [sendMethod, setSendMethod] = useState<SendingMethods>(
    SendingMethods.ACCOUNT_DEPOSIT
  );
  const [selectedCountry, setSelectedCountry] = useState<ICountry>({
    alpha2Code: "MX",
    countryCode: "MEX",
    countryName: "Mexico",
    defaultCurrency: "MXN",
    demonym: "Mexican",
    dialCode: "52",
    displayOrder: "12",
    numericCode: "484",
    officialName: "United Mexican States",
  });

  const { countries, destinations, getCountries, getDestinations } =
    useCatalogStore();
  const { quote, getQuote, setQuote } = useQuoterStore();

  const amountToReceive: string = useMemo(() => {
    if (!quote || !quote?.deliveryMethods?.[0]) return "$0";

    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });

    return formatter.format(quote.deliveryMethods[0].amountToReceive || 0);
  }, [quote]);
  const exchangeRate: string | undefined = useMemo(() => {
    if (!quote || !quote?.deliveryMethods?.[0]) return undefined;

    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });

    return formatter.format(quote.deliveryMethods[0].fx);
  }, [quote]);
  const fee: string | undefined = useMemo(() => {
    if (!quote || !quote?.deliveryMethods?.[0]) return undefined;

    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });

    return formatter.format(quote?.deliveryMethods?.[0]?.fee);
  }, [quote]);

  const countryOptions = useMemo(() => {
    if (!countries) return [];

    const countryMap = countries.map((country) => {
      return {
        label: country.countryName,
        value: country.countryCode,
        customElement: (
          <img
            className="w-6 h-6 object-cover rounded-full"
            src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${country.alpha2Code}.svg`}
          />
        ),
      };
    });

    return countryMap
      ?.filter((country) => ALLOWED_COUNTRIES.includes(country.value))
      ?.sort((a, b) => (a.value > b.value ? 1 : -1));
  }, [countries]);

  const destinationsOptions = useMemo(() => {
    if (!destinations) return [];

    return destinations.map((destination) => {
      return {
        label: destination.destination,
        value: destination.id,
      };
    });
  }, [destinations]);

  const handleCountryChange = (value: string) => {
    setSelectedDestination(undefined);
    const country = countries.find((country) => country.countryCode === value);

    if (country) {
      setSelectedCountry(country);
    }
  };

  const handleDestinationChange = (value: string) => {
    const destination = destinations.find(
      (destination) => destination.id === value
    );

    if (destination) {
      setSelectedDestination(destination);
    }
  };

  const changeQuoteAmount = useDebounce((value: number) => {
    setQuoteAmount(value);
  }, 400);

  const handleAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (Number(event.target.value) < MAX_AMOUNT) {
      setAmount(Number(event.target.value));
      changeQuoteAmount(Number(event.target.value));
      return;
    }

    setAmount(MAX_AMOUNT);
    changeQuoteAmount(MAX_AMOUNT);
  };

  useEffect(() => {
    setLoadingCountries(true);
    getCountries(currentLanguage).finally(() => {
      setLoadingCountries(false);
    });
  }, [currentLanguage]);

  useEffect(() => {
    setLoadingDestinations(true);
    getDestinations(sendMethod, selectedCountry.countryCode)
      .then(() => {
        setQuote(null);
        setSelectedDestination(undefined);
      })
      .finally(() => {
        setLoadingDestinations(false);
      });
  }, [selectedCountry, sendMethod]);

  useEffect(() => {
    if (
      !quoteAmount ||
      !selectedDestination?.payerQuote ||
      !selectedCountry?.countryCode ||
      !selectedCountry?.defaultCurrency
    ) {
      setQuote(null);
      return;
    }

    setLoadingQuote(true);
    getQuote(
      selectedCountry.countryCode,
      selectedCountry.defaultCurrency,
      quoteAmount,
      selectedDestination.payerQuote
    ).finally(() => {
      setLoadingQuote(false);
    });
  }, [sendMethod, selectedDestination, selectedCountry, quoteAmount]);

  return (
    <Card
      className="w-full flex justify-center border-t-[1px] border-gray-200 shadow-gray-200 lg:shadow-none lg:rounded-large lg:p-12"
      raised
    >
      <div className="w-full flex flex-col items-center px-2 gap-2 lg:gap-4 max-w-screen-sm">
        <div className="w-full flex flex-col gap-1">
          <p className="text-sm text-purple-800">{t("Quoter.Amount")}</p>
          <div className="w-full flex justify-between gap-2 h-10">
            <Input
              className="!border-0 font-semibold !px-0 text-xl"
              type="number"
              value={amount.toString()}
              onChange={handleAmountChange}
              block
            />
            <div className="h-full w-max overflow-hidden flex gap-1 p-2 flex-shrink-0 items-center">
              <img
                className="w-6 h-6 rounded-full object-cover"
                src={USIcon}
                alt="Language flag"
              />
              <p className="font-semibold w-10">USD</p>
              <div className="w-4" />
            </div>
          </div>
        </div>

        <div className="w-full h-0 border-b-2 border-gray-400 -my-2" />

        <div className="w-full flex flex-col gap-1">
          <p className="text-sm text-purple-800">{t("Quoter.Receive")}</p>
          <div className="flex justify-between gap-2 h-8">
            {loadingQuote ? (
              <LoadingIcon className="w-5 h-5 text-gray-400 animate-spin" />
            ) : (
              <p className="flex items-center py-2 font-semibold text-xl w-full">
                {amountToReceive}
              </p>
            )}
            <div className="h-full w-max flex gap-1 p-2 flex-shrink-0 items-center overflow-visible">
              <Select
                icon={
                  <img
                    className="w-6 h-6 rounded-full object-cover"
                    src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${selectedCountry?.alpha2Code}.svg`}
                    alt="Language flag"
                  />
                }
                containerClassName="p-0 w-24"
                value={selectedCountry?.defaultCurrency}
                className="font-semibold text-gray-800 !border-0 gap-1 !px-1"
                options={countryOptions}
                loading={loadingCountries}
                onChange={handleCountryChange}
                alignMenuRight
                block
              />
            </div>
          </div>

          {quote && (
            <>
              <div className="flex gap-2 justify-between bg-indigo-200 rounded px-2 py-1 mt-2 font-semibold">
                <p className="text-xs">{t("Quoter.ExchangeRate")}</p>
                <p className="text-xs">
                  {exchangeRate} {selectedCountry?.defaultCurrency}
                </p>
              </div>
              <div className="flex gap-2 justify-between bg-indigo-200 rounded px-2 py-1 mt-2 font-semibold">
                <p className="text-xs">{t("Quoter.Fee")}</p>
                <p className="text-xs">{fee} USD</p>
              </div>
            </>
          )}
        </div>

        <Selector
          options={[
            {
              label: t("Quoter.Account"),
              value: SendingMethods.ACCOUNT_DEPOSIT,
            },
            {
              label: t("Quoter.Cash"),
              value: SendingMethods.CASH_PICK_UP,
            },
          ]}
          onChange={(value: string) => setSendMethod(value as SendingMethods)}
        />

        <Select
          className="font-semibold text-gray-800 border-0"
          placeholder={t("Quoter.SelectPlaceholder")}
          onChange={handleDestinationChange}
          value={selectedDestination?.destination || ""}
          options={destinationsOptions}
          loading={loadingDestinations}
          block
        />

        <Button
          text={t("Quoter.Button")}
          color="purple"
          link={AUTH_DOMAIN}
          className="from-purple-700 to-indigo-800 hover:from-purple-600 hover:to-indigo-700 bg-gradient-to-b !border-0 font-normal py-3 px-8 my-2"
        />
        <div className="w-full flex gap-2 items-center text-purple-800">
          <InformationCircleIcon className="w-6 h-6 flex-shrink-0" />
          <div className="flex flex-col gap-2">
            <p className="text-xs">{t("Quoter.Disclaimer2")}</p>
          </div>
        </div>
      </div>
    </Card>
  );
};
