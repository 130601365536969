import { useTranslation } from "react-i18next";
import { CreateAccountBanner } from "@/components/CreateAccountBanner/CreateAccountBanner.component";
import { StepCard } from "@/components/StepCard/StepCard.component";
import SmilingWoman from "@/assets/Img/smiling-woman.jpg";
import SignUpMockEs from "@/assets/Img/sign-up-mock-es.png";
import SignUpMockEn from "@/assets/Img/sign-up-mock-en.png";
import LinkAccountMockEs from "@/assets/Img/link-account-mock-es.png";
import LinkAccountMockEn from "@/assets/Img/link-account-mock-en.png";
import YoungManSteps from "@/assets/Img/young-man-on-steps.jpg";
import SendMoneyMockEs from "@/assets/Img/send-money-mock-es.png";
import SendMoneyMockEn from "@/assets/Img/send-money-mock-en.png";
import VideoZelleEn from "@/assets/Video/video-zelle-en.mp4";
import VideoZelleEs from "@/assets/Video/video-zelle-es.mp4";

export default function HowItWorksPage() {
  const { t, i18n } = useTranslation("global");
  const lang = i18n.language;

  return (
    <div className="w-full flex flex-col">
      <div className="relative w-full h-72 md:h-[32rem] lg:h-[40rem]">
        <img
          className="w-full h-full absolute top-0 left-0 object-cover object-top"
          src={SmilingWoman}
          alt="Smiling woman using phone"
        />
        <div className="absolute top-0 left-0 w-full h-full bg-black/60 flex justify-center items-center">
          <h1 className="text-4xl lg:text-6xl 2xl:text-7xl text-white font-semibold lg:-translate-y-10">
            {t("HowItWorks.Heading")}
          </h1>
        </div>
      </div>

      <div className="w-full flex flex-col items-center gap-6 lg:gap-10 p-6 lg:px-10 -translate-y-8 rounded-t-3xl lg:-mb-12 lg:-translate-y-12 lg:rounded-t-large bg-gradient-to-b from-[#7062ff] to-[#5f00cb]">
        <h1 className="text-3xl lg:text-5xl 2xl:text-6xl font-semibold w-full text-center text-white mt-4">
          {t("HowItWorks.Section1.Title")}
        </h1>

        <div className="w-full -mb-14 rounded-large grid grid-cols-1 gap-6 md:grid-cols-2 max-w-screen-2xl">
          <StepCard
            step={1}
            title={t("HowItWorks.Section1.Step1.Title")}
            content={t("HowItWorks.Section1.Step1.Paragraph")}
            imageSrc={lang === "es" ? SignUpMockEs : SignUpMockEn}
          />
          <StepCard
            step={2}
            title={t("HowItWorks.Section1.Step2.Title")}
            content={t("HowItWorks.Section1.Step2.Paragraph")}
            imageSrc={lang === "es" ? LinkAccountMockEs : LinkAccountMockEn}
          />
        </div>
      </div>

      <div className="w-full flex flex-col mt-20 lg:mt-28 pb-10 px-6 lg:px-10 gap-28 items-center">
        <div className="w-full flex flex-col gap-4 max-w-screen-2xl">
          <h1 className="text-3xl lg:text-5xl 2xl:text-6xl font-semibold w-full text-center">
            {t("HowItWorks.Section2.Title")}
          </h1>

          <div className="w-full relative max-w-screen-2xl">
            <div className="w-[92%] translate-y-6 h-full absolute right-0 top-0 -z-10">
              <div className="relative w-full h-full">
                <img
                  className="w-full h-full absolute top-0 left-0 object-cover rounded-3xl"
                  src={YoungManSteps}
                  alt="Smiling woman using phone"
                />
                <div className="w-full h-full absolute top-0 left-0 object-cover rounded-3xl bg-black/55" />
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2">
              <div className="flex flex-col gap-6 w-full max-w-[40rem] pr-4 md:pr-0">
                <StepCard
                  className="p-6 shadow-lg !shadow-black/25"
                  step={1}
                  title={t("HowItWorks.Section2.Step1.Title")}
                  content={t("HowItWorks.Section2.Step1.Paragraph")}
                  stepNumberVariant="secondary"
                  titleInline
                />
                <StepCard
                  className="p-6 shadow-lg !shadow-black/25"
                  step={2}
                  title={t("HowItWorks.Section2.Step2.Title")}
                  content={t("HowItWorks.Section2.Step2.Paragraph")}
                  stepNumberVariant="secondary"
                  titleInline
                />
                <StepCard
                  className="p-6 shadow-lg !shadow-black/25"
                  step={3}
                  title={t("HowItWorks.Section2.Step3.Title")}
                  content={t("HowItWorks.Section2.Step3.Paragraph")}
                  stepNumberVariant="secondary"
                  titleInline
                />
              </div>

              <div className="hidden w-full h-full md:flex items-center justify-center">
                <img
                  className="max-h-[48rem] object-contain"
                  src={lang === "es" ? SendMoneyMockEs : SendMoneyMockEn}
                  alt="Send money mockup"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="w-full flex flex-col items-center gap-8 max-w-screen-2xl">
          <div>
            <h1 className="text-3xl lg:text-5xl 2xl:text-6xl font-semibold w-full text-center">
              {t("HowItWorks.Section3.Title")}
            </h1>
            <h3 className="text-lg lg:text-2xl 2xl:text-3xl w-full text-center text-stone-400">
              {t("HowItWorks.Section3.Subtitle")}
            </h3>
          </div>

          <div className="rounded-3xl overflow-hidden max-w-[72rem]">
            <video
              autoPlay
              loop
              muted
              controls={false}
              playsInline
              height={1080}
              width={1920}
              disablePictureInPicture
              disableRemotePlayback
              className="w-full h-fit min-h-full max-w-full"
            >
              <source
                src={lang === "en" ? VideoZelleEn : VideoZelleEs}
                type="video/mp4"
              />
            </video>
          </div>
        </div>

        <CreateAccountBanner
          className="max-w-screen-2xl"
          title={t("Home.BankAccount.Title")}
          subtitle={t("Home.BankAccount.Subtitle")}
          buttonText={t("Home.BankAccount.Button")}
        />
      </div>
    </div>
  );
}
