import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { StickyCards } from "@/components/StickyCards";
import { Button } from "@/components/Button";
import { Carousel } from "@/components/Carousel";
import { TestimonialCard } from "@/components/TestimonialCard";
import { GlobalPaymentsQuoter } from "@/components/GlobalPaymentsQuoter";
import { ArrowRightEndOnRectangleIcon } from "@heroicons/react/24/outline";
import { CreateAccountBanner } from "@/components/CreateAccountBanner/CreateAccountBanner.component";
import WhatsappLogo from "@/assets/Icons/whatsapp.svg?react";
import classNames from "classnames";
import VideoCoverEn from "@/assets/Img/video-cover-en.png";
import VideoCoverEs from "@/assets/Img/video-cover-es.png";
import AnthonyImage from "@/assets/Img/Anthony.png";
import ReneeImage from "@/assets/Img/Renee.jpg";
import MarianaImage from "@/assets/Img/Mariana.png";
import CountriesGifEs from "@/assets/Gifs/countries_es.gif";
import CountriesGifEn from "@/assets/Gifs/countries_en.gif";
import HappySmilingGuy from "@/assets/Img/happy-smiling-guy.jpg";
import SmilingYoungWoman from "@/assets/Img/smiling-young-woman.jpg";
import { Helmet } from "react-helmet";

const AuthUrl = import.meta.env.VITE_AUTH_DOMAIN;

export default function HomePage() {
  const [t, i18n] = useTranslation("global");
  const lang = i18n.language;

  const [openModal, setOpenModal] = useState(false);

  const countries = useMemo(() => {
    return [
      { icon: "MX", name: t("Countries.MX") },
      { icon: "IN", name: t("Countries.IN") },
      { icon: "GT", name: t("Countries.GT") },
      { icon: "PH", name: t("Countries.PH") },
      { icon: "VN", name: t("Countries.VN") },
      { icon: "SV", name: t("Countries.SV") },
      { icon: "HN", name: t("Countries.HN") },
      { icon: "NI", name: t("Countries.NI") },
      { icon: "CA", name: t("Countries.CA") },
      { icon: "PA", name: t("Countries.PA") },
    ];
  }, [t]);

  useEffect(() => {
    if (!window) return;

    if (window.location?.hash) {
      const id = window.location.hash.replace("#", "");
      const element = document.getElementById(id);

      if (element) element.scrollIntoView();
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Sendola | Inicio</title>
      </Helmet>

      <div className="w-full pb-20">
        <div className="flex flex-col relative">
          <div
            className={classNames(
              "flex justify-center gap-2 bg-[#590099] h-fit rounded-b-2xl overflow-hidden",
              {
                "lg:h-fit lg:max-h-[1000px] xl:py-32 lg:-translate-y-20 lg:!rounded-none":
                  true, // Desktop styles
              }
            )}
          >
            {/* <video
              autoPlay
              loop
              muted
              controls={false}
              playsInline
              height={1080}
              width={1920}
              poster={
                lang === "en"
                  ? "/Img/video-cover-en.jpg"s
                  : "/Img/video-cover-es.jpg"
              }
              disablePictureInPicture
              disableRemotePlayback
              className="w-full h-fit min-w-full min-h-full max-w-full top-0 left-0 right-0"
            >
              <source src="/Video/home-video.mp4" type="video/mp4" />
            </video> */}
            <img
              className="object-contain w-full max-w-[1080px] select-none min-h-64 md:min-h-[28rem] 2xl:min-h-[24rem]"
              src={lang === "en" ? VideoCoverEn : VideoCoverEs}
              alt={t("Home.SendMoney")}
            />
          </div>
        </div>

        {/* Quoter */}
        <div className="w-full flex flex-col items-center px-6 lg:px-10 py-2 gap-4 -mb-8 -translate-y-8 rounded-3xl lg:-mb-32 lg:-translate-y-32 lg:rounded-t-large top-shadow lg:pt-10 bg-white">
          <div className="flex flex-col gap-2 w-full py-2 -mb-2 max-w-screen-sm 2xl:max-w-screen-md lg:hidden">
            <div className="flex items-center">
              <h2 className="lg:text-2xl text-xl font-semibold text-purple-700 mr-2.5">
                {t("Home.ScrollCallout")}
              </h2>
              <img
                className="h-20 sm:h-32 lg:h-44 -my-6 sm:-my-10 inline object-contain max-w-36"
                src={lang === "es" ? CountriesGifEs : CountriesGifEn}
                alt="gif countries"
              />
            </div>
          </div>
          <div
            className="w-full lg:shadow-xl lg:rounded-large max-lg:!bg-none"
            style={{
              backgroundImage: `url(${HappySmilingGuy})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="w-full flex flex-col items-center lg:bg-black/60 lg:flex-row lg:justify-between lg:rounded-large">
              <div className="w-1/2 hidden lg:block p-8">
                <div className="flex items-center">
                  <h2 className="text-5xl 2xl:text-6xl font-semibold inline-block text-white mr-2.5">
                    {t("Home.ScrollCallout")}{" "}
                    <img
                      className="h-40 2xl:h-48 -my-6 sm:-my-10 inline object-contain"
                      src={lang === "es" ? CountriesGifEs : CountriesGifEn}
                      alt="gif countries"
                    />
                  </h2>
                </div>
              </div>

              <div className="w-full lg:w-1/2 max-w-screen-sm lg:max-w-none">
                <GlobalPaymentsQuoter />
              </div>
            </div>
          </div>
        </div>

        {/* Hide on desktop */}
        <div
          className="flex flex-col items-center gap-4 w-full p-6 pb-0 lg:hidden"
          id="signIn"
        >
          <div className="flex flex-col gap-4 items-center w-full max-w-screen-sm 2xl:max-w-screen-md">
            <div className="flex flex-col gap-2 w-full">
              <h1 className="text-xl text-purple-600 font-semibold leading-none">
                {t("Home.LoginTitle")}
              </h1>
            </div>
            <div className="inline-flex justify-around w-full gap-4 lg:gap-6">
              <Button
                className="shadow-xl py-4 min-w-40 w-full"
                text={t("Home.LoginButton")}
                link={`${AuthUrl}/sign-in`}
              />

              <Button
                className="py-4 min-w-40 w-full"
                text={t("Home.SignupButton")}
                variant="secondary"
                onClick={() => setOpenModal(true)}
              />
            </div>
          </div>
        </div>

        <div id="countries" className="hidden w-full px-10 mt-20 lg:block">
          <div
            className="rounded-large"
            style={{
              backgroundImage: `url(${SmilingYoungWoman})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="flex items-center w-full h-full bg-black/60 justify-between p-8 rounded-large">
              <div className="flex flex-col gap-2 w-1/3 h-full">
                {countries.map((country, index) => (
                  <div
                    key={`country-${index}`}
                    className="w-full rounded-full flex items-center gap-2 bg-white shadow-lg p-2"
                  >
                    <img
                      className="w-8 h-8 rounded-full object-cover"
                      src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${country.icon}.svg`}
                      alt={`${country.name}'s Flag`}
                    />
                    <span className="w-full font-semibold">{country.name}</span>
                  </div>
                ))}
              </div>
              <div className="w-2/3 flex justify-center items-center">
                <p className="text-white text-5xl font-semibold px-4 text-center">
                  {t("Home.CountriesWeService")}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          id="cards"
          className="flex flex-col items-center px-6 py-6 lg:px-10 lg:py-20"
        >
          <StickyCards />
        </div>

        <div className="w-full px-6 lg:px-10">
          <CreateAccountBanner
            title={t("Home.BankAccount.Title")}
            subtitle={t("Home.BankAccount.Subtitle")}
            buttonText={t("Home.BankAccount.Button")}
            hideOnMobile
          />
        </div>

        <div
          id="testimonials"
          className="w-full flex justify-center px-6 lg:px-10 pb-10 lg:hidden"
        >
          <div className="flex flex-col gap-4 w-full max-w-screen-sm 2xl:max-w-screen-md">
            <div className="w-full md:h-full md:w-5/12">
              <p className="text-sm text-purple-800 font-semibold lg:text-base">
                {t("Home.Section4.Title")}
              </p>
            </div>

            <Carousel
              items={[
                {
                  id: "1",
                  content: (
                    <TestimonialCard
                      title={t("Home.Testimonial1.Name")}
                      subtitle={t("Home.Testimonial1.Ocupation")}
                      paragraph={t("Home.Testimonial1.Content")}
                      imageUrl={AnthonyImage}
                    />
                  ),
                },
                {
                  id: "2",
                  content: (
                    <TestimonialCard
                      title={t("Home.Testimonial2.Name")}
                      subtitle={t("Home.Testimonial2.Ocupation")}
                      paragraph={t("Home.Testimonial2.Content")}
                      imageUrl={ReneeImage}
                    />
                  ),
                },
                {
                  id: "3",
                  content: (
                    <TestimonialCard
                      title={t("Home.Testimonial3.Name")}
                      subtitle={t("Home.Testimonial3.Ocupation")}
                      paragraph={t("Home.Testimonial3.Content")}
                      imageUrl={MarianaImage}
                    />
                  ),
                },
              ]}
            />
          </div>
        </div>

        <div
          id="hs-basic-modal"
          className={`hs-overlay hs-overlay-open:opacity-100 hs-overlay-open:duration-500 fixed top-0 start-0 z-[80] w-full h-full opacity-0 overflow-x-hidden transition-all overflow-y-auto pointer-events-none ${
            openModal ? "opacity-100 pointer-events-auto" : "hidden"
          }`}
          role="dialog"
          tabIndex={-1}
          aria-labelledby="hs-basic-modal-label"
          style={{ background: "rgba(0, 0, 0, 0.70)" }}
        >
          <div className="sm:max-w-lg sm:w-full m-3 sm:mx-auto">
            <div className="flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto dark:border-neutral-700 dark:shadow-neutral-700/70 p-6">
              <div className="flex justify-between md:justify-center items-center mb-4">
                <h3 id="hs-basic-modal-label" className="font-semibold">
                  {t("Home.Modal.title")}
                </h3>
              </div>
              <div className="mx-auto w-full text-center">
                <button
                  className="flex shadow-xl items-center mx-auto justify-center font-semibold py-2 px-10 bg-indigo-500 border-2 border-indigo-500 text-white rounded-full border-box hover:opacity-90 w-fit text-base mb-4"
                  onClick={() => window.open(`${AuthUrl}/sign-up`)}
                >
                  <ArrowRightEndOnRectangleIcon className="w-6 h-6 mr-2" />
                  {t("Home.Modal.singUpButton")}
                </button>
                <div className="mb-4 flex">
                  <span className="border-y w-full h-0 slate-200 my-auto" />
                  <span className="mx-2 text-gray-500 text-sm">
                    {t("Home.Modal.or")}
                  </span>
                  <span className="border-y w-full h-0 slate-200 my-auto" />
                </div>
                <div className="mb-4 text-center">
                  <a
                    href={`https://api.whatsapp.com/send?phone=18325251941&text=${t(
                      "Home.Modal.hi"
                    )}`}
                    rel="Sing up"
                    target="_blank"
                    className="flex text-indigo-500 text-xs font-semibold text-center justify-center cursor-pointer"
                  >
                    <WhatsappLogo className="w-4 h-4 mr-2" />
                    {t("Home.Modal.whatsappButton")}
                  </a>
                </div>
                <button
                  className="text-gray-400"
                  onClick={() => setOpenModal(false)}
                >
                  {t("Home.Modal.back")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
