import { Card } from "@/components/Card";
import { PromiseCard } from "@/components/PromiseCard/PromiseCard.component";
import { useTranslation } from "react-i18next";
import CashDown from "@/assets/Icons/cash-down.svg?react";
import OnTimeDelivery from "@/assets/Icons/ontime-delivery.svg?react";
import CashOut from "@/assets/Icons/cash-out.svg?react";
import ShieldCheck from "@/assets/Icons/shield-check.svg?react";
import HappyManUsingPhone from "@/assets/Img/happy-man-using-phone.jpg";
import Enrique from "@/assets/Img/enrique.jpg";

export default function WhySendolaPage() {
  const { t } = useTranslation("global");

  return (
    <div className="w-full flex flex-col">
      <div className="relative w-full h-72 md:h-[32rem] lg:h-[40rem]">
        <img
          className="w-full h-full absolute top-0 left-0 object-cover"
          src={HappyManUsingPhone}
          alt="Happy man using phone"
        />
        <div className="absolute top-0 left-0 w-full h-full bg-black/60 flex justify-center items-center">
          <h1 className="text-4xl lg:text-6xl 2xl:text-7xl text-white font-semibold lg:-translate-y-10">
            {t("WhySendola.Heading")}
          </h1>
        </div>
      </div>

      <div className="w-full flex flex-col items-center gap-6 lg:gap-12 p-6 -mb-8 -translate-y-8 rounded-3xl lg:-mb-10 lg:-translate-y-10 lg:rounded-t-large lg:p-10 bg-white">
        <div className="flex flex-col pt-4">
          <h1 className="text-3xl lg:text-5xl font-semibold w-full text-center">
            {t("WhySendola.Promise")}
          </h1>
          <h2 className="text-xl lg:text-3xl font-semibold w-full text-center text-stone-400">
            {t("WhySendola.Mision")}
          </h2>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6 lg:gap-x-10 max-w-screen-2xl">
          <PromiseCard
            icon={<CashDown className="w-10 h-10 text-indigo-600" />}
            title={t("WhySendola.Cards.Card1.Title")}
            paragraph={t("WhySendola.Cards.Card1.Paragraph")}
          />
          <PromiseCard
            icon={<OnTimeDelivery className="w-10 h-10 text-indigo-600" />}
            title={t("WhySendola.Cards.Card2.Title")}
            paragraph={t("WhySendola.Cards.Card2.Paragraph")}
          />
          <PromiseCard
            icon={<CashOut className="w-10 h-10 text-indigo-600" />}
            title={t("WhySendola.Cards.Card3.Title")}
            paragraph={t("WhySendola.Cards.Card3.Paragraph")}
          />
          <PromiseCard
            icon={<ShieldCheck className="w-10 h-10 text-indigo-600" />}
            title={t("WhySendola.Cards.Card4.Title")}
            paragraph={t("WhySendola.Cards.Card4.Paragraph")}
          />
        </div>

        <div className="relative flex flex-col items-center w-full lg:pr-[10%] xl:pr-[6%] -mb-10 lg:mb-10 max-w-screen-2xl">
          <div className="w-full relative h-[24rem] md:h-[32rem] lg:h-[40rem] 2xl:h-[48rem]">
            <img
              className="w-full h-full absolute top-0 left-0 rounded-xl md:rounded-large object-cover"
              src={Enrique}
              alt="Sendola's CEO"
            />
            <p className="text-white font-semibold absolute top-4 w-full text-center text-2xl md:text-3xl px-3 lg:text-6xl lg:w-fit lg:max-w-80 lg:top-auto lg:bottom-10 lg:left-10 text-wrap">
              {t("WhySendola.MessageFromCeo.Title")}
            </p>
          </div>
          <Card
            raised
            className="md:!rounded-large relative right-0 bottom-10 lg:-bottom-10 w-full md:w-5/6 flex items-center justify-center p-6 md:p-10 lg:w-5/12 lg:absolute"
          >
            <p
              className="text-sm md:text-base xl:text-lg text-stone-500 inner-span-highlight inner-span-bolden"
              dangerouslySetInnerHTML={{
                __html: t("WhySendola.MessageFromCeo.Content"),
              }}
            />
          </Card>
        </div>

        <div className="flex items-center justify-center w-full text-center bg-gradient-to-b rounded-large h-32 md:h-44 lg:h-60 from-[#735fff] to-[#5e00d0] px-10 max-w-screen-2xl">
          <p
            className="text-xl md:text-4xl lg:text-5xl inner-span-kasual inner-span-highlight font-semibold text-white"
            dangerouslySetInnerHTML={{ __html: t("WhySendola.BankingBanner") }}
          />
        </div>

        <div className="flex flex-col w-full max-w-screen-2xl">
          <div className="w-full flex flex-col gap-2 bg-white rounded-large shadow-lg shadow-black/15 px-10 pt-16 pb-32 border-t-stone-100 border-t-2">
            <p
              className="text-sm md:text-base lg:text-lg text-stone-500 font-medium inner-span-purple inner-span-bolden"
              dangerouslySetInnerHTML={{
                __html: t("WhySendola.Texts.Card1.Paragraph1"),
              }}
            />
            <p
              className="text-sm md:text-base lg:text-lg text-stone-500 font-medium inner-span-purple inner-span-bolden"
              dangerouslySetInnerHTML={{
                __html: t("WhySendola.Texts.Card1.Paragraph2"),
              }}
            />
            <p
              className="text-sm md:text-base lg:text-lg text-stone-500 font-medium inner-span-purple inner-span-bolden"
              dangerouslySetInnerHTML={{
                __html: t("WhySendola.Texts.Card1.Paragraph3"),
              }}
            />
          </div>
          <div className="flex flex-col gap-3 w-full bg-[#7166ff] min-h-20 -mt-20 rounded-large shadow-lg shadow-black/15 px-10 pt-16 pb-32">
            <p
              className="text-sm lg:text-base text-white font-medium inner-span-purple inner-span-bolden"
              dangerouslySetInnerHTML={{
                __html: t("WhySendola.Texts.Card2.Paragraph1"),
              }}
            />
            <p
              className="text-sm lg:text-base text-white font-medium inner-span-purple inner-span-bolden"
              dangerouslySetInnerHTML={{
                __html: t("WhySendola.Texts.Card2.Paragraph2"),
              }}
            />
            <p
              className="text-sm lg:text-base text-white font-medium inner-span-purple inner-span-bolden"
              dangerouslySetInnerHTML={{
                __html: t("WhySendola.Texts.Card2.Paragraph3"),
              }}
            />
            <p
              className="text-sm lg:text-base text-white font-medium inner-span-purple inner-span-bolden"
              dangerouslySetInnerHTML={{
                __html: t("WhySendola.Texts.Card2.Paragraph4"),
              }}
            />
          </div>
          <div className="flex flex-col gap-6 w-full bg-white min-h-20 -mt-20 rounded-large shadow-lg shadow-black/15 px-10 py-20">
            <h1
              className="text-3xl md:text-4xl xl:text-5xl text-indigo-500 font-semibold inner-span-black inner-span-bolden"
              dangerouslySetInnerHTML={{
                __html: t("WhySendola.Texts.Card3.Title"),
              }}
            />
            <p
              className="text-sm lg:text-base text-stone-500 inner-span-black inner-span-bolden"
              dangerouslySetInnerHTML={{
                __html: t("WhySendola.Texts.Card3.Paragraph1"),
              }}
            />
            <p
              className="text-sm lg:text-base text-stone-500 inner-span-black inner-span-bolden"
              dangerouslySetInnerHTML={{
                __html: t("WhySendola.Texts.Card3.Paragraph2"),
              }}
            />
            <p
              className="text-sm lg:text-base text-stone-500 inner-span-black inner-span-bolden"
              dangerouslySetInnerHTML={{
                __html: t("WhySendola.Texts.Card3.Paragraph3"),
              }}
            />
            <p
              className="text-sm lg:text-base text-stone-500 inner-span-black inner-span-bolden"
              dangerouslySetInnerHTML={{
                __html: t("WhySendola.Texts.Card3.Paragraph4"),
              }}
            />
            <div>
              <p
                className="text-base lg:text-lg font-semibold text-center"
                dangerouslySetInnerHTML={{
                  __html: t("WhySendola.Texts.Card3.Signature1"),
                }}
              />
              <p
                className="text-lg lg:text-2xl font-semibold text-center"
                dangerouslySetInnerHTML={{
                  __html: t("WhySendola.Texts.Card3.Signature2"),
                }}
              />
              <p
                className="text-base lg:text-lg text-stone-500 text-center"
                dangerouslySetInnerHTML={{
                  __html: t("WhySendola.Texts.Card3.Signature3"),
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
