import { useTranslation } from "react-i18next";
import { NationalMarketBanner } from "@/components/NationalMarketBanner/NationalMarketBanner.component";
import { JoinBankNetworkBanner } from "@/components/JoinBankNetworkBanner/JoinBankNetworkBanner.component";
import TextDecorationLeft from "@/assets/Icons/text-decoration-left.svg?react";
import TextDecorationRight from "@/assets/Icons/text-decoration-right.svg?react";
import Globe from "@/assets/Icons/globe.svg?react";
import PeopleStar from "@/assets/Icons/people-star.svg?react";
import Withdrawal from "@/assets/Icons/withdrawal.svg?react";
import Handshake from "@/assets/Icons/handshake.svg?react";
import BankIcon from "@/assets/Icons/bank-icon.svg?react";
import CardInclinedIcon from "@/assets/Icons/card-inclined.svg?react";
import CashGlobalIcon from "@/assets/Icons/cash-global.svg?react";
import PeopleStarIcon from "@/assets/Icons/people-star.svg?react";
import StarShineIcon from "@/assets/Icons/star-shine.svg?react";
import PhoneCashIcon from "@/assets/Icons/phone-cash.svg?react";
import Arrow from "@/assets/Icons/arrow.svg?react";
import Checkmark from "@/assets/Icons/checkmark.svg?react";
import MeetingImage from "@/assets/Img/for-partners-meeting.jpg";
import WomanGraphic from "@/assets/Img/image-woman-graphic.png";
import PartnersGraphic from "@/assets/Video/partners-graphic.mp4";
import MapStateLocations from "@/assets/Img/map-state-locations.png";
import UsiLogo from "@/assets/Img/PartnersLogos/usi-logo.png";
import BannerBankLogo from "@/assets/Img/PartnersLogos/banner-bank-logo.png";
import MarisolFcu from "@/assets/Img/PartnersLogos/marisol-fcu.png";
import LogoSendola from "@/assets/Img/logo-sendola.png";
import MexicanWorker from "@/assets/Img/mexican-worker.jpg";
import ATM from "@/assets/Img/atm.png";
import { Card } from "@/components/Card";
import { CoutriesGraphic } from "@/components/CountriesGraphic";
import { StepCard } from "@/components/StepCard/StepCard.component";
import { Button } from "@/components/Button";
import { Modal } from "@/components/Modal";
import { useState } from "react";
import HubspotContactForm from "@/components/HubspotContactForm";
import { HubspotFormData } from "@/interfaces/hubspot.interface";

interface FormDataType {
  en: HubspotFormData;
  es: HubspotFormData;
}

const formsData: FormDataType = {
  en: {
    region: "na1",
    portalId: "44207752",
    formId: "65e52c09-80ed-415e-9fa9-60be7997fac6",
  },
  es: {
    region: "na1",
    portalId: "44207752",
    formId: "a0c17685-b5a6-473a-a5aa-2c1eb55969e7",
  },
};

export default function ForPartnersPage() {
  const { t, i18n } = useTranslation("global");
  const lang = i18n.language;
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [loadingForm, setLoadingForm] = useState<boolean>(false);

  return (
    <>
      <div className="w-full flex flex-col">
        <div className="relative w-full h-72 md:h-[32rem] lg:h-[40rem]">
          <img
            className="w-full h-full absolute top-0 left-0 object-cover object-top"
            src={MeetingImage}
            alt="People on a meeting"
          />
          <div className="absolute top-0 left-0 w-full h-full bg-black/60 flex flex-col justify-center items-center">
            <h1 className="text-4xl lg:text-6xl 2xl:text-7xl text-center text-white font-semibold lg:-translate-y-10">
              {t("ForPartners.Heading")}
            </h1>
            <Button
              className="max-lg:mt-4"
              text={t("ForPartners.Button")}
              onClick={() => {
                setIsModalOpen(true);
                setLoadingForm(true);
              }}
            />
          </div>
        </div>

        <div className="w-full flex flex-col items-center py-6 gap-6 lg:gap-10 -translate-y-8 rounded-t-3xl lg:-mb-12 lg:-translate-y-12 lg:rounded-t-large bg-white">
          <div className="w-full flex flex-col gap-4 items-center px-6 lg:px-10">
            <h1 className="text-3xl md:text-5xl font-bold w-full mt-4 text-center">
              {t("ForPartners.Section1.Title")}
            </h1>
            <div className="flex items-center">
              <TextDecorationLeft className="h-20 md:h-28 max-w-24 text-[#db3b63]" />
              <p
                className="text-xl -mx-4 md:text-3xl w-full max-w-[800px] mt-4 text-center inner-span-highlight inner-span-kasual"
                dangerouslySetInnerHTML={{
                  __html: t("ForPartners.Section1.Subtitle"),
                }}
              />
              <TextDecorationRight className="h-20 md:h-28 max-w-24 text-[#db3b63]" />
            </div>

            <div className="w-full md:w-[80%] max-w-[1000px] overflow-hidden">
              <video
                autoPlay
                loop
                muted
                controls={false}
                playsInline
                width={1368}
                height={646}
                disablePictureInPicture
                disableRemotePlayback
                poster={WomanGraphic}
                className="max-h-full h-full w-full object-cover scale-[1.02]"
              >
                <source src={PartnersGraphic} type="video/mp4" />
              </video>
            </div>
          </div>

          <div className="px-6 lg:px-10 flex flex-col gap-6 lg:gap-10">
            <NationalMarketBanner className="max-w-screen-2xl" />

            <CoutriesGraphic />
          </div>

          <div className="w-full flex flex-col items-center gap-10 mt-20">
            <div className="px-10">
              <h1 className="text-4xl lg:text-5xl 2xl:text-6xl text-center font-bold">
                {t("ForPartners.Section4.Title")}
              </h1>
              <p className="text-xl lg:text-2xl 2xl:text-3xl text-center font-semibold text-stone-400 leading-6 mt-2">
                {t("ForPartners.Section4.Subtitle")}
              </p>
            </div>

            <div className="flex flex-col items-center w-full bg-gray-100">
              <div className="flex items-center justify-center w-full bg-indigo-600 md:grid-cols mt-10 text-white h-36">
                <p
                  className="text-center text-3xl md:text-4xl lg:text-5xl inner-b-kasual inner-span-kasual inner-span-highlight"
                  dangerouslySetInnerHTML={{
                    __html: t("ForPartners.Section4.Zelle"),
                  }}
                />
              </div>
              <div className="w-full py-10 px-6 lg:px-10 grid grid-cols-1 md:grid-cols-2 gap-6 max-w-screen-2xl">
                <Card className="shadow-none flex items-center gap-4 !rounded-3xl min-h-44">
                  <Globe className="text-indigo-500 h-16 w-16 flex-shrink-0" />
                  <div>
                    <p className="font-semibold text-lg lg:text-xl">
                      {t("ForPartners.Section4.Cards.Card1.Title")}
                    </p>
                    <p className="text-sm text-stone-400">
                      {t("ForPartners.Section4.Cards.Card1.Content")}
                    </p>
                  </div>
                </Card>
                <Card className="shadow-none flex items-center gap-4 !rounded-3xl min-h-44">
                  <PeopleStar className="text-indigo-500 h-16 w-16 flex-shrink-0" />
                  <div>
                    <p className="font-semibold text-lg lg:text-xl">
                      {t("ForPartners.Section4.Cards.Card2.Title")}
                    </p>
                    <p className="text-sm text-stone-400">
                      {t("ForPartners.Section4.Cards.Card2.Content")}
                    </p>
                  </div>
                </Card>
                <Card className="shadow-none flex items-center gap-4 !rounded-3xl min-h-44">
                  <Withdrawal className="text-indigo-500 h-16 w-16 flex-shrink-0" />
                  <div>
                    <p className="font-semibold text-lg lg:text-xl">
                      {t("ForPartners.Section4.Cards.Card3.Title")}
                    </p>
                    <p className="text-sm text-stone-400">
                      {t("ForPartners.Section4.Cards.Card3.Content")}
                    </p>
                  </div>
                </Card>
                <Card className="shadow-none flex items-center gap-4 !rounded-3xl min-h-44">
                  <Handshake className="text-indigo-500 h-16 w-16 flex-shrink-0" />
                  <div>
                    <p className="font-semibold text-lg lg:text-xl">
                      {t("ForPartners.Section4.Cards.Card4.Title")}
                    </p>
                    <p className="text-sm text-stone-400">
                      {t("ForPartners.Section4.Cards.Card4.Content")}
                    </p>
                  </div>
                </Card>
              </div>
            </div>

            <div className="w-full flex flex-col max-w-screen-2xl gap-6 px-6 lg:px-10">
              <div className="flex flex-col gap-2">
                <h1 className="text-4xl lg:text-5xl 2xl:text-6xl text-center font-bold">
                  {t("ForPartners.Section5.Title")}
                </h1>
                <p className="text-xl lg:text-2xl 2xl:text-3xl text-center font-semibold text-stone-400">
                  {t("ForPartners.Section5.Subtitle")}
                </p>
                <h1 className="lg:text-lg text-center text-stone-400">
                  {t("ForPartners.Section5.Paragraph")}
                </h1>
              </div>

              <div className="w-full flex flex-col items-center">
                <div className="w-full md:w-[90%] flex flex-col items-center bg-white shadow-lg border-t-2 border-gray-100 rounded-3xl px-3 pt-3 md:px-6 pb-20">
                  <div className="h-full mb-6 py-4">
                    <img
                      className="object-contain w-full h-fit max-w-[1000px] max-sm:max-h-[280px] sm:max-h-[420px] lg:max-h-[700px]"
                      src={MapStateLocations}
                      alt="Map with country locations"
                    />
                  </div>

                  <div className="w-fit bg-white shadow-lg border-t-2 border-gray-100 rounded-3xl lg:rounded-full flex flex-wrap justify-center gap-10 items-center py-6 px-6 lg:px-20">
                    <div className="flex gap-2 items-center">
                      <div className="w-8 h-8 flex-shrink-0 rounded-full bg-[#fea544]" />
                      <img
                        className="object-contain w-auto h-fit max-h-10"
                        src={UsiLogo}
                        alt="USI Logo"
                      />
                    </div>
                    <div className="flex gap-2 items-center">
                      <div className="w-8 h-8 flex-shrink-0 rounded-full bg-[#6f66ff]" />
                      <img
                        className="object-contain w-auto h-fit max-h-10"
                        src={BannerBankLogo}
                        alt="Banner Bank Logo"
                      />
                    </div>
                    <div className="flex gap-2 items-center">
                      <div className="w-8 h-8 flex-shrink-0 rounded-full bg-[#e24099]" />
                      <img
                        className="object-contain w-auto h-fit max-h-10"
                        src={MarisolFcu}
                        alt="Banner Bank Logo"
                      />
                    </div>
                  </div>

                  <div className="flex justify-center mt-10">
                    <h2
                      className="text-xl md:text-2xl lg:text-3xl text-center inner-span-kasual inner-span-indigo"
                      dangerouslySetInnerHTML={{
                        __html: t("ForPartners.Section5.Title2"),
                      }}
                    />
                  </div>
                </div>
                <div className="flex flex-col justify-center w-full rounded-3xl bg-transparent md:bg-gradient-to-b from-indigo-500 to-indigo-700 md:p-6 mt-10 md:-mt-10">
                  <div className="hidden md:grid grid-cols-3 gap-4 w-full text-white -mb-6">
                    <p className="inner-span-kasual text-5xl lg:text-6xl text-center">
                      <span>{t("ForPartners.Section5.You")}</span>
                    </p>
                    <img
                      className="w-full h-auto max-h-16 -translate-y-2 object-contain object-center"
                      src={LogoSendola}
                      alt="Sendola's logo"
                    />
                    <p className="inner-span-kasual inner-span-highlight text-5xl lg:text-6xl text-center">
                      <span>{t("ForPartners.Section5.Together")}</span>
                    </p>
                  </div>
                  <div className="w-full hidden md:flex justify-evenly px-20 -mb-10 z-10">
                    <Arrow className="w-1/3 text-pink-600 h-24" />
                    <Arrow className="w-1/3 text-pink-600 h-24" />
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-3 md:gap-6 w-full">
                    <div className="max-md:bg-indigo-600 max-md:rounded-3xl max-md:p-4 h-fit md:h-full">
                      <p className="inner-span-kasual text-5xl text-center text-white md:hidden my-6">
                        <span>{t("ForPartners.Section5.You")}</span>
                      </p>
                      <div className="flex flex-row md:flex-col items-center lg:flex-row lg:justify-around w-full h-full border-2 border-white rounded-3xl gap-4 text-white p-4">
                        <div className="flex flex-col flex-shrink-0 w-1/2 items-center justify-center">
                          <BankIcon className="w-16 h-16 flex-shrink-0" />
                          <p className="text-center text-sm font-semibold">
                            {t("ForPartners.Section5.Icons.Icon1")}
                          </p>
                        </div>
                        <div className="flex flex-col flex-shrink-0 w-1/2 items-center justify-center">
                          <CardInclinedIcon className="w-16 h-16 flex-shrink-0" />
                          <p className="text-center text-sm font-semibold">
                            {t("ForPartners.Section5.Icons.Icon2")}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="w-full flex justify-center h-6 md:hidden">
                      <div className="h-full w-1 bg-indigo-600" />
                    </div>

                    <div className="max-md:bg-indigo-600 max-md:rounded-3xl max-md:p-4 h-fit md:h-full">
                      <img
                        className="w-full h-auto max-h-14 object-contain object-center md:hidden my-6"
                        src={LogoSendola}
                        alt="Sendola's logo"
                      />
                      <div className="flex flex-row md:flex-col items-center lg:flex-row lg:justify-around w-full h-full border-2 border-white rounded-3xl gap-4 text-white p-4">
                        <div className="flex flex-col flex-shrink-0 w-1/2 items-center justify-center">
                          <CashGlobalIcon className="w-16 h-16 flex-shrink-0" />
                          <p className="text-center text-sm font-semibold">
                            {t("ForPartners.Section5.Icons.Icon3")}
                          </p>
                        </div>
                        <div className="flex flex-col flex-shrink-0 w-1/2 items-center justify-center">
                          <PeopleStarIcon className="w-16 h-16 flex-shrink-0" />
                          <p className="text-center text-sm font-semibold">
                            {t("ForPartners.Section5.Icons.Icon4")}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="w-full flex justify-center h-6 md:hidden">
                      <div className="h-full w-1 bg-indigo-600" />
                    </div>

                    <div className="max-md:bg-indigo-600 max-md:rounded-3xl max-md:p-4 h-fit md:h-full">
                      <p className="inner-span-kasual inner-span-highlight text-5xl text-center md:hidden my-8">
                        <span>{t("ForPartners.Section5.Together")}</span>
                      </p>
                      <div className="flex flex-row md:flex-col items-center lg:flex-row lg:justify-around w-full h-full border-2 border-white rounded-3xl gap-4 text-white p-4">
                        <div className="flex flex-col flex-shrink-0 w-1/2 items-center justify-center">
                          <StarShineIcon className="w-16 h-16 flex-shrink-0" />
                          <p className="text-center text-sm font-semibold">
                            {t("ForPartners.Section5.Icons.Icon5")}
                          </p>
                        </div>
                        <div className="flex flex-col flex-shrink-0 w-1/2 items-center justify-center">
                          <PhoneCashIcon className="w-16 h-16 flex-shrink-0" />
                          <p className="text-center text-sm font-semibold">
                            {t("ForPartners.Section5.Icons.Icon6")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full lg:px-10 flex flex-col items-center gap-6 lg:gap-10 mt-20 rounded-t-large bg-gray-100 px-6 pt-6 md:px-10 mb-10">
            <div className="w-full flex flex-col max-w-screen-2xl gap-6">
              <div className="flex flex-col gap-2">
                <h1 className="text-4xl lg:text-5xl 2xl:text-6xl text-center font-bold">
                  {t("ForPartners.Section6.Title")}
                </h1>
                <p
                  className="text-2xl lg:text-3xl 2xl:text-5xl text-center font-semibold inner-span-indigo inner-span-kasual"
                  dangerouslySetInnerHTML={{
                    __html: t("ForPartners.Section6.Subtitle"),
                  }}
                />
              </div>

              <div className="w-full flex flex-col sm:flex-row items-center gap-4 justify-evenly">
                <div className="flex flex-col justify-around gap-4 md:gap-10 h-full md:py-10 min-w-fit">
                  <div className="sm:max-w-44 md:max-w-44 lg:max-w-72 xl:max-w-[400px] 2xl:max-w-[500px] relative">
                    <p className="font-semibold text-lg md:text-xl lg:text-2xl">
                      {t("ForPartners.Section6.Points.Point1.Title")}
                    </p>
                    <p
                      className="text-sm md:text-base"
                      dangerouslySetInnerHTML={{
                        __html: t("ForPartners.Section6.Points.Point1.Content"),
                      }}
                    />
                  </div>
                  <div className="sm:max-w-44 md:max-w-44 lg:max-w-72 xl:max-w-[400px] 2xl:max-w-[500px] relative">
                    <p className="font-semibold text-lg md:text-xl lg:text-2xl">
                      {t("ForPartners.Section6.Points.Point2.Title")}
                    </p>
                    <p
                      className="text-sm md:text-base"
                      dangerouslySetInnerHTML={{
                        __html: t("ForPartners.Section6.Points.Point2.Content"),
                      }}
                    />
                  </div>
                  <div className="sm:max-w-44 md:max-w-44 lg:max-w-72 xl:max-w-[400px] 2xl:max-w-[500px] relative">
                    <p className="font-semibold text-lg md:text-xl lg:text-2xl">
                      {t("ForPartners.Section6.Points.Point3.Title")}
                    </p>
                    <p
                      className="text-sm md:text-base"
                      dangerouslySetInnerHTML={{
                        __html: t("ForPartners.Section6.Points.Point3.Content"),
                      }}
                    />
                  </div>
                </div>
                <div className="w-full h-full flex flex-col justify-around gap-10 py-10 pl-4 -mx-6 max-sm:hidden">
                  <div className="relative w-full h-[1px] bg-gray-400 before:content-[''] before:w-[8px] before:h-[8px] before:rounded-full before:bg-gray-400 before:absolute before:-left-[4px] before:-top-[4px]" />
                  <div className="relative w-full h-[1px] bg-gray-400 before:content-[''] before:w-[8px] before:h-[8px] before:rounded-full before:bg-gray-400 before:absolute before:-left-[4px] before:-top-[4px]" />
                  <div className="relative w-full h-[1px] bg-gray-400 before:content-[''] before:w-[8px] before:h-[8px] before:rounded-full before:bg-gray-400 before:absolute before:-left-[4px] before:-top-[4px]" />
                </div>
                <img
                  className="w-[200px] md:w-[300px] flex-shrink-0"
                  src={ATM}
                  alt="Sendola ATM"
                />
                <div className="w-full h-full flex flex-col justify-around gap-10 py-10 pr-4 -mx-6 max-sm:hidden">
                  <div className="relative w-full h-[1px] bg-gray-400 before:content-[''] before:w-[8px] before:h-[8px] before:rounded-full before:bg-gray-400 before:absolute before:-right-[4px] before:-top-[4px]" />
                  <div className="relative w-full h-[1px] bg-gray-400 before:content-[''] before:w-[8px] before:h-[8px] before:rounded-full before:bg-gray-400 before:absolute before:-right-[4px] before:-top-[4px]" />
                  <div className="relative w-full h-[1px] bg-gray-400 before:content-[''] before:w-[8px] before:h-[8px] before:rounded-full before:bg-gray-400 before:absolute before:-right-[4px] before:-top-[4px]" />
                </div>
                <div className="flex flex-col justify-around gap-10 h-full py-10 min-w-fit">
                  <div className="sm:max-w-44 md:max-w-44 lg:max-w-72 xl:max-w-[400px] 2xl:max-w-[500px] relative">
                    <p className="font-semibold text-lg md:text-xl lg:text-2xl pl-2 sm:text-right">
                      {t("ForPartners.Section6.Points.Point4.Title")}
                    </p>
                    <p
                      className="text-sm md:text-base pl-2 sm:text-right"
                      dangerouslySetInnerHTML={{
                        __html: t("ForPartners.Section6.Points.Point4.Content"),
                      }}
                    />
                  </div>
                  <div className="sm:max-w-44 md:max-w-44 lg:max-w-72 xl:max-w-[400px] 2xl:max-w-[500px] relative">
                    <p className="font-semibold text-lg md:text-xl lg:text-2xl pl-2 sm:text-right">
                      {t("ForPartners.Section6.Points.Point5.Title")}
                    </p>
                    <p
                      className="text-sm md:text-base pl-2 sm:text-right"
                      dangerouslySetInnerHTML={{
                        __html: t("ForPartners.Section6.Points.Point5.Content"),
                      }}
                    />
                  </div>
                  <div className="sm:max-w-44 md:max-w-44 lg:max-w-72 xl:max-w-[400px] 2xl:max-w-[500px] relative">
                    <p className="font-semibold text-lg md:text-xl lg:text-2xl pl-2 sm:text-right">
                      {t("ForPartners.Section6.Points.Point6.Title")}
                    </p>
                    <p
                      className="text-sm md:text-base pl-2 sm:text-right"
                      dangerouslySetInnerHTML={{
                        __html: t("ForPartners.Section6.Points.Point6.Content"),
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="w-full flex justify-center">
                <div className="rounded-3xl bg-purple-500 text-white px-10 py-4">
                  <p className="w-full text-center font-semibold text-2xl md:text-3xl lg:text-4xl">
                    {t("ForPartners.Section6.CostToYou")}
                  </p>
                  <p className="w-full text-center font-semibold text-4xl md:text-5xl lg:text-6xl inner-span-kasual">
                    <span>{t("ForPartners.Section6.Cost")}</span>
                  </p>
                </div>
              </div>

              <h1 className="text-4xl lg:text-5xl 2xl:text-6xl text-center font-bold mt-6">
                {t("ForPartners.Section6.Network")}
              </h1>

              <div className="w-full hidden md:flex justify-evenly px-32 -mb-14 z-10">
                <Arrow className="w-1/3 text-pink-600 h-20" />
                <Arrow className="w-1/3 text-pink-600 h-20" />
              </div>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6 -mb-10">
                <StepCard
                  step={1}
                  title={t("ForPartners.Section6.Steps.Step1.Title")}
                  content={t("ForPartners.Section6.Steps.Step1.Content")}
                />
                <StepCard
                  step={2}
                  title={t("ForPartners.Section6.Steps.Step2.Title")}
                  content={t("ForPartners.Section6.Steps.Step2.Content")}
                />
                <StepCard
                  step={3}
                  title={t("ForPartners.Section6.Steps.Step3.Title")}
                  content={t("ForPartners.Section6.Steps.Step3.Content")}
                />
              </div>
            </div>
          </div>

          <div className="w-full relative flex flex-col items-center md:items-end px-6 mt-10 md:px-10 2xl:p-0 2xl:max-w-screen-2xl">
            <img
              className="h-auto object-cover max-sm:max-h-60 w-full sm:w-[80%] md:w-[60%] xl:w-[50%] max-w-[800px] rounded-3xl"
              src={MexicanWorker}
              alt="Mexican Worker"
            />

            <div className="md:absolute w-full h-full top-0 left-0 md:pl-10 flex max-md:justify-center max-md:mt-10 items-center">
              <div className="w-full sm:w-[80%] md:w-[50%] flex flex-col gap-4 items-center md:items-start">
                <h1 className="font-semibold text-4xl md:text-5xl lg:texl-6xl mb-4">
                  {t("ForPartners.Section7.Title")}
                </h1>
                {[1, 2, 3, 4, 5, 6].map((_, index) => (
                  <div
                    key={`check-${index}`}
                    className="flex items-center gap-4 bg-white last:bg-indigo-600 last:text-white w-full rounded-full shadow-lg py-1 px-2"
                  >
                    <Checkmark className="w-14 h-14 -m-1 shrink-0" />
                    <p
                      className="font-semibold text-sm lg:text-base"
                      dangerouslySetInnerHTML={{
                        __html: t(
                          `ForPartners.Section7.Points.Point${index + 1}`
                        ),
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="px-6 lg:px-10 flex flex-col gap-6 lg:gap-10 mt-20">
            <div className="flex flex-col gap-8 w-full rounded-3xl bg-gradient-to-b from-indigo-500 to-indigo-800 text-white p-6 lg:px-10 max-w-screen-2xl">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-20 items-center">
                <h1 className="text-3xl lg:text-4xl 2xl:text-6xl font-bold">
                  {t("ForPartners.Section8.Title")}
                </h1>
                <p className="text-sm lg:text-base md:col-span-2 text-justify">
                  {t("ForPartners.Section8.Paragraph")}
                </p>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                {[1, 2, 3, 4, 5, 6].map((_, index) => {
                  return (
                    <Card
                      key={`card-${index}`}
                      className="shadow-none flex flex-col gap-2 min-h-32"
                    >
                      <h2 className="text-xl lg:text-2xl font-semibold text-black">
                        {t(`ForPartners.Section8.Card${index + 1}.Title`)}
                      </h2>
                      <p className="text-sm lg:text-base text-stone-400">
                        {t(`ForPartners.Section8.Card${index + 1}.Content`)}
                      </p>
                    </Card>
                  );
                })}
              </div>
            </div>

            <JoinBankNetworkBanner
              title={t("ForPartners.Section9.Title")}
              subtitle={t("ForPartners.Section9.Subtitle")}
              buttonText={t("ForPartners.Section9.Button")}
              onClickButton={() => {
                setIsModalOpen(true);
                setLoadingForm(true);
              }}
            />
          </div>
        </div>
      </div>

      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        {loadingForm && <p className="w-full text-center">{t("Loading")}...</p>}
        <HubspotContactForm
          region={formsData[lang as "es" | "en"].region}
          portalId={formsData[lang as "es" | "en"].portalId}
          formId={formsData[lang as "es" | "en"].formId}
          onReady={() => {
            setLoadingForm(false);
          }}
        />
      </Modal>
    </>
  );
}
